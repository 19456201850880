import {RenderBuffer} from "./render-buffer";
import {Bomb, Explosion} from "../engine/models/bombs";

export function renderActiveBombs(bombs: Bomb[], buffer: RenderBuffer): void {
    for (const bomb of bombs) {
        const cell = buffer[bomb.position.height][bomb.position.width]
        cell.sprites.push({ imageUrl: 'flame.png' })
    }
}

export function renderExplosions(explosions: Explosion[], buffer: RenderBuffer): void {
    for (const explosion of explosions) {
        for(let i = explosion.position.width; i <= explosion.position.width + explosion.right; i++) {
            buffer[explosion.position.height][i].sprites.push({ imageUrl: 'boom_side.png', flip: { width: true } })
        }
        for(let i = explosion.position.width; i >= explosion.position.width - explosion.left; i--) {
            buffer[explosion.position.height][i].sprites.push({ imageUrl: 'boom_side.png' })
        }
        for(let i = explosion.position.height; i <= explosion.position.height + explosion.bottom; i++) {
            buffer[i][explosion.position.width].sprites.push({ imageUrl: 'boom_top.png', flip: { height: true } })
        }
        for(let i = explosion.position.height; i >= explosion.position.height - explosion.top; i--) {
            buffer[i][explosion.position.width].sprites.push({ imageUrl: 'boom_top.png' })
        }
    }

    for (const explosion of explosions) {
        const cell = buffer[explosion.position.height][explosion.position.width]
        cell.sprites = [{ imageUrl: 'bomb_explosion.png' }]
    }
}
