import {RenderBuffer} from "./render-buffer";
import {getMobileSprite, Params} from "./mobile-render";
import {Monster, Monsters} from "../engine/models/monsters";

export function renderMonsters(monsters: Readonly<Monsters>, buffer: RenderBuffer): void {
    for(const eel of monsters.eels) {
        renderMonster(eel, buffer, { sideImageUrl: 'eel_side.png', topImageUrl: 'eel_top.png' })
    }
    for(const squid of monsters.squids) {
        renderMonster(squid, buffer, { sideImageUrl: 'squid_side.png', topImageUrl: 'squid_top.png' })
    }
}

function renderMonster(monster: Readonly<Monster>, buffer: RenderBuffer, params: Params): void {
    const cell = buffer[monster.position.cell.height][monster.position.cell.width]
    cell.sprites.push({
        ...getMobileSprite(monster, params)
    })
}
