import React, {CSSProperties} from 'react';
import './Scene.css';
import {Cell, RenderBuffer, Sprite, SpriteFlip, SpriteShift} from "./render/render-buffer";

interface Params {
  buffer: RenderBuffer
}

function Scene({ buffer }: Params) {
    let i = 0
    const map = buffer.map(row => (
        <tr key={i++}>
            {
                row.map(cell => (
                    <td key={i++}>
                        { sprites(cell) }
                    </td>
                ))
            }
        </tr>
    ))

    return (
        <div className="scene">
            <table className="area">
                <tbody>
                    { map }
                </tbody>
            </table>
        </div>
    );
}

function sprites(cell?: Cell): React.ReactElement {
    return (
        <>
            {
                cell?.sprites.map((sprite, index) =>  (
                        <div className={`sprite ${index > 0 ? 'next': ''}`} key={index} style={shift(sprite.shift)}>
                            { renderSprite(sprite) }
                        </div>
                    )
                )
            }
        </>
    )
}

function shift(shift?: SpriteShift): CSSProperties {
    if (shift?.width || shift?.height) {
        return {
            position: "relative",
            left: `${100*shift?.width/4}%`,
            top: `${100*shift?.height/4}%`,
        }
    }
    return { }
}

function renderSprite(sprite?: Sprite): React.ReactElement {
    if (sprite?.symbol) {
        return (
            <>{ sprite.symbol }</>
        )
    } else if (sprite?.imageUrl) {
        return (
            <img src={`images/${sprite.imageUrl}`} alt="" title="" className={flip(sprite.flip)} />
        )
    }
    return (<></>)
}

function flip(flip?: SpriteFlip): string {
    return [
        flip?.width ? "flipWidth": undefined,
        flip?.height ? "flipHeight": undefined,
    ].filter(c => c).join(' ')
}

export default Scene;
