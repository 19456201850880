import {RenderBuffer, Sprite} from "./render-buffer";
import {CellType, forEach, Map} from "../engine/models/map";

export function renderMap(map: Map, buffer: RenderBuffer): void {
    forEach(map, (point, value) => {
        buffer[point.height][point.width] = {
            sprites: [{
                ...mapCell(value),
                shift: undefined,
            }]
        }
    })
}

function mapCell(cellType: CellType): Partial<Sprite> {
    switch (cellType) {
        case CellType.Bricks: return { imageUrl: 'seaweed.png' }
        case CellType.Wall: return { imageUrl: 'stones.png' }
        case CellType.Empty: return { }
        default: return { }
    }
}
