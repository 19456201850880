import {Monster, Monsters} from "../models/monsters";
import {CellType, Map} from "../models/map";
import {Direction} from "../models/mobile";
import {Point} from "../models/point";

export class MonstersGenerator {
    public generate(map: Map): Monsters {
        return {
            eels: Array.from(this.createMonsters(10, map)),
            squids: Array.from(this.createMonsters(5, map))
        }
    }

    private *createMonsters(count: number, map: Map): Iterable<Monster> {
        for (let i = 0; i < count; i++) {
            yield (this.createMonster(map))
        }
    }

    private createMonster(map: Map): Monster {
        return {
            position: {
                cell: this.getRandomEmptyCell(map),
                shift: { width: 0, height: 0 }
            },
            direction: Direction.Right,
        }
    }

    private getRandomEmptyCell(map: Map): Point {
        let cell: Point
        do {
            cell = {
                width: Math.ceil(Math.random() * (map.size.width - 1)),
                height: Math.ceil(Math.random() * (map.size.height - 1)),
            }
        } while (map.get(cell) !== CellType.Empty)
        return cell
    }
}
