import {Explode, Explodes, MobileExplode} from "../models/explodes";

export class ExplodesGenerator {
    public generate(): Explodes {
        return {
            bricks: new Array<Explode>(),
            monsters: new Array<MobileExplode>(),
        }
    }
}
