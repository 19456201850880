import {Command} from "./command";
import {CommandAction, CommandsEmitter} from "./command-emitter";
import {SimpleEmitter} from "./emitter";

export class KeyCommandsEmitter implements CommandsEmitter {
    private readonly mapping: Record<string, Command | undefined> = {
        'w': Command.Up,
        's': Command.Down,
        'a': Command.Left,
        'd': Command.Right,
        ' ': Command.Put,
    }

    private readonly activateEmitter = new SimpleEmitter<Command>()

    private readonly deactivateEmitter = new SimpleEmitter<Command>()

    constructor() {
        this.addListeners()
    }

    addListener(activate: CommandAction, deactivate?: CommandAction): void {
        this.activateEmitter.addListener(activate)
        if (deactivate) {
            this.deactivateEmitter.addListener(deactivate)
        }
    }

    removeListener(activate: CommandAction, deactivate?: CommandAction): void {
        this.activateEmitter.removeListener(activate)
        if (deactivate) {
            this.deactivateEmitter.removeListener(deactivate)
        }
    }

    private addListeners() {
        window.addEventListener('keydown', e => {
            const command = this.mapping[e.key]
            if (command) {
                this.activateEmitter.emit(command)
            }
        })
        window.addEventListener('keyup', e => {
            const command = this.mapping[e.key]
            if (command) {
                this.deactivateEmitter.emit(command)
            }
        })
    }
}
