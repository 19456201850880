import {CellType, Map} from "../models/map";
import {Point} from "../models/point";
import {Direction, MobileObject} from "../models/mobile";
import {Bombs} from "../models/bombs";

export class MobileObjectMovement {
    private lastTime: number = performance.now()

    constructor(private map: Map, private bombs: Bombs, public readonly minInterval = 100) {
    }

    public move(mobile: MobileObject, to: Direction): boolean | undefined {
        if (this.skip()) {
            return
        }

        let { cell, shift } = mobile.position;

        switch (to) {
            case Direction.Up:
                cell = { height: cell.height - 1, width: cell.width }
                if (this.canBeMovedTo(cell)) {
                    shift = { height: shift.height - 1, width: 0 }
                } else {
                    shift = { height: Math.max(shift.height - 1, 0), width: shift.width }
                }

                break;
            case Direction.Down:
                cell = { height: cell.height + 1, width: cell.width }
                if (this.canBeMovedTo(cell)) {
                    shift = { height: shift.height + 1, width: 0 }
                } else {
                    shift = { height: Math.min(shift.height + 1, 0), width: shift.width }
                }

                break;
            case Direction.Left:
                cell = { height: cell.height, width: cell.width - 1 }
                if (this.canBeMovedTo(cell)) {
                    shift = { height: 0, width: shift.width - 1 }
                } else {
                    shift = { height: shift.height, width: Math.max(shift.width - 1, 0) }
                }

                break;
            case Direction.Right:
                cell = { height: cell.height, width: cell.width + 1 }
                if (this.canBeMovedTo(cell)) {
                    shift = { height: 0, width: shift.width + 1 }
                } else {
                    shift = { height: shift.height, width: Math.min(shift.width + 1, 0) }
                }

                break;
        }

        if (this.canBeMovedTo(cell)) {
            if (Math.abs(shift.width) > 2 || Math.abs(shift.height) > 2) {
                mobile.position.cell = cell
                shift = { height: Math.sign(shift.height) * -2, width: Math.sign(shift.width) * -2}
            }
        }
        const prevShift = mobile.position.shift
        mobile.position.shift = shift
        mobile.direction = to

        return prevShift.height !== shift.height || prevShift.width !== shift.width
    }

    public canBeMovedTo(cell: Point) {
        return this.map.get(cell) === CellType.Empty && (
            !this.bombs.active.some(bomb => bomb.position.height === cell.height && bomb.position.width === cell.width)
        )
    }

    private skip(): boolean {
        const now = performance.now()
        if (now - this.lastTime < this.minInterval) {
            return true
        }
        this.lastTime = now
        return false
    }
}
