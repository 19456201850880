import {Scene} from "./models/scene";
import {PlayerMovement} from "./logic/player-movement";
import {BombPlacer} from "./logic/bomb-placer";
import {BombsHandler} from "./logic/bombs-handler";
import {ExplosionRemover} from "./logic/explosion-remover";
import {BricksExploder} from "./logic/bricks-exploder";
import {ExplodesRemover} from "./logic/explodes-remover";
import {MonstersMovement} from "./logic/monsters-movement";
import {MonsterExploder} from "./logic/monster-exploder";
import {PlayerMonsterCollisions} from "./logic/player-monster-collisions";
import {PlayerExploder} from "./logic/player-exploder";
import {CommandsEmitter} from "./input/command-emitter";

export class GameLoop {
    private readonly movement: PlayerMovement;
    private readonly bombPlacer: BombPlacer;
    private readonly bombsHandler: BombsHandler;
    private readonly explosionRemover: ExplosionRemover;
    private readonly bricksExploder: BricksExploder;
    private readonly explodesRemover: ExplodesRemover;
    private readonly monstersMovement: MonstersMovement;
    private readonly monsterExploder: MonsterExploder;
    private readonly playerMonsterCollisions: PlayerMonsterCollisions;
    private readonly playerExploder: PlayerExploder;

    constructor(public readonly scene: Scene, public readonly commands: CommandsEmitter) {
        this.movement = new PlayerMovement(commands, scene.map, scene.bombs, scene.player)

        this.bombPlacer = new BombPlacer(commands, scene.player, scene.bombs, scene.parameters)

        this.bombsHandler = new BombsHandler(scene.map, scene.bombs, scene.parameters)

        this.explosionRemover = new ExplosionRemover(scene.bombs)

        this.bricksExploder = new BricksExploder(scene.map, scene.bombs, scene.explodes)

        this.explodesRemover = new ExplodesRemover(scene.explodes)

        this.monstersMovement = new MonstersMovement(scene.map, scene.player, scene.bombs, scene.monsters)

        this.monsterExploder = new MonsterExploder(scene.bombs, scene.monsters, scene.explodes)

        this.playerMonsterCollisions = new PlayerMonsterCollisions(scene.player, scene.monsters)

        this.playerExploder = new PlayerExploder(scene.player, scene.bombs)
    }

    public next(): void {
        this.movement.move()
        this.monstersMovement.move()
        this.playerMonsterCollisions.check()
        this.playerExploder.explodePlayer()
        this.bombPlacer.placeBomb()
        this.bombsHandler.explodeBombs()
        this.bricksExploder.explodeBricks()
        this.monsterExploder.explodeMonsters()
        this.explosionRemover.removeExplosions()
        this.explodesRemover.removeExplodes()
    }
}