import {Bombs} from "../models/bombs";
import {all, Monster, Monsters} from "../models/monsters";
import {ExplosionCalculator} from "./explosion-calculator";
import {Explodes} from "../models/explodes";

export class MonsterExploder {
    constructor(
        private readonly bombs: Readonly<Bombs>,
        private readonly monsters: Monsters,
        private readonly explodes: Explodes,
    ) {
    }

    public explodeMonsters() {
        const killedMonsters = new Set<Monster>()

        for (const monster of all(this.monsters)) {
            for (const explosion of this.bombs.explosions) {
                if (ExplosionCalculator.isInExplosion(monster.position.cell, explosion)) {
                    this.explodes.monsters.push({
                        position: monster.position,
                        removeAt: performance.now() + 3000
                    })
                    killedMonsters.add(monster)
                }
            }
        }

        this.monsters.eels = this.monsters.eels.filter(monster => !killedMonsters.has(monster))
        this.monsters.squids = this.monsters.squids.filter(monster => !killedMonsters.has(monster))
    }
}