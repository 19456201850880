import {Player} from "../models/player";
import {Bombs} from "../models/bombs";
import {Command} from "../input/command";
import {EventCommandsProvider} from "../input/command-providers";
import {KeyCommandsEmitter} from "../input/key-command-emitter";
import {CommandsEmitter} from "../input/command-emitter";

interface Params {
    readonly explodeDelay: number
}

export class BombPlacer {
    private readonly commandsProvider = new EventCommandsProvider(new KeyCommandsEmitter())

    constructor(
        public readonly commands: CommandsEmitter,
        private readonly player: Readonly<Player>,
        private readonly bombs: Bombs,
        private readonly params: Readonly<Params>,
    ) {
        this.commandsProvider = new EventCommandsProvider(commands)
    }

    public placeBomb(): void {
        if (this.commandsProvider.get() === Command.Put) {
            const position = this.player.position.cell
            const existingBomb = this.bombs.active.find(b => b.position.width === position.width && b.position.height === position.height)

            if (existingBomb == null) {
                this.bombs.active.push({
                    position,
                    explodeAt: performance.now() + this.params.explodeDelay
                })
            }
        }
    }
}
