import {MapGenerator, MapParameters} from "./map-generator";
import {Scene} from "../models/scene";
import {PlayerGenerator} from "./player-generator";
import {BombsGenerator} from "./bombs-generator";
import {ExplodesGenerator} from "./explodes-generator";
import {MonstersGenerator} from "./monsters-generator";

export class SceneGenerator {
    private readonly mapGenerator: MapGenerator
    private readonly playerGenerator: PlayerGenerator
    private readonly bombsGenerator: BombsGenerator
    private readonly explodesGenerator: ExplodesGenerator
    private readonly monstersGenerator: MonstersGenerator;

    constructor(public params: SceneParameters) {
        this.mapGenerator = new MapGenerator(params.map)
        this.playerGenerator = new PlayerGenerator()
        this.bombsGenerator = new BombsGenerator()
        this.explodesGenerator = new ExplodesGenerator()
        this.monstersGenerator = new MonstersGenerator()
    }

    public generate(): Scene {
        const map = this.mapGenerator.generate()
        const scene: Scene = {
            map,
            player: this.playerGenerator.generate(),
            bombs: this.bombsGenerator.generate(),
            explodes: this.explodesGenerator.generate(),
            monsters: this.monstersGenerator.generate(map),
            parameters: { explodeSize: 3, explodeDelay: 5000}
        }

        return scene
    }
}

export interface SceneParameters{
    readonly map: MapParameters
}
