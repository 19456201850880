import {Scene} from "../engine/models/scene";
import {arrayOf} from "../common/array-2d";
import {renderMap} from "./map-render";
import {renderActiveBombs, renderExplosions} from "./bombs-render";
import {renderExplodes} from "./explodes-render";
import {renderPlayer} from "./player-render";
import {RenderBuffer} from "./render-buffer";
import {renderMonsters} from "./monsters-render";

export class SceneRender {
    private readonly buffer: RenderBuffer

    constructor(public scene: Scene) {
        this.buffer = arrayOf(scene.map.size.height, scene.map.size.width, { sprites: [{ }] })
    }

    public render(): RenderBuffer {
        renderMap(this.scene.map, this.buffer)
        renderExplosions(this.scene.bombs.explosions, this.buffer)
        renderExplodes(this.scene.explodes, this.buffer)
        renderMonsters(this.scene.monsters, this.buffer)
        renderActiveBombs(this.scene.bombs.active, this.buffer)
        renderPlayer(this.scene.player, this.buffer)

        return this.buffer
    }
}