export function arrayOf<T>(d1: number, d2: number, def: T): Array<Array<T>> {
    const array = new Array<Array<T>>(d1)
    for (let i = 0; i < d1; i++){
        const row = array[i] = new Array<T>(d2)
        for (let q = 0; q < d2; q++){
            row[q] = def
        }
    }
    return array
}
