import {Bombs} from "../models/bombs";

export class ExplosionRemover {
    constructor(
        private readonly bombs: Bombs,
    ) {
    }

    public removeExplosions(): void {
        const now = performance.now()
        const explosions = new Set(this.bombs.explosions)

        for(const explosion of this.bombs.explosions) {
            if (explosion.removeAt !== undefined && explosion.removeAt <= now) {
                explosions.delete(explosion)
            }
        }

        if (explosions.size !== this.bombs.explosions.length) {
            this.bombs.explosions = Array.from(explosions.values())
        }
    }
}
