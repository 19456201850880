import {Map as SceneMap} from "../models/map";
import {MobileObjectMovement} from "./mobile-object-movement";
import {Monster, Monsters} from "../models/monsters";
import {Direction} from "../models/mobile";
import {Bombs} from "../models/bombs";
import {Player} from "../models/player";

export class MonstersMovement {
    private readonly movers = new Map<Monster, MobileObjectMovement>()

    constructor(
        private map: SceneMap,
        private player: Player,
        private bombs: Bombs,
        private monsters: Monsters) {
    }

    public move(): void {
        for (const eel of this.monsters.eels) {
            const mover = this.getMover(eel, /*interval*/ 100)
            this.moveEel(eel, mover)
        }

        for (const eel of this.monsters.squids) {
            const mover = this.getMover(eel, /*interval*/ 600)
            this.moveSquid(eel, mover)
        }
    }

    private moveEel(eel: Monster, mover: MobileObjectMovement): void {
        if (mover.move(eel, eel.direction) === false) {
            switch (Math.floor(Math.random() * 5)) {
                case 0: eel.direction = Direction.Up
                    break
                case 1: eel.direction = Direction.Down
                    break
                case 2: eel.direction = Direction.Left
                    break
                case 3: eel.direction = Direction.Right
                    break
            }
        }
    }

    private moveSquid(squid: Monster, mover: MobileObjectMovement): void {
        const newDirection = (): Direction  => {
            const heightDiff = this.player.position.cell.height - squid.position.cell.height
            const widthDiff = this.player.position.cell.width - squid.position.cell.width

            if (Math.abs(heightDiff) > Math.abs(widthDiff)) {
                if (heightDiff > 0) {
                    return Direction.Down
                } else {
                    return Direction.Up
                }
            } else {
                if (widthDiff > 0) {
                    return Direction.Right
                } else {
                    return Direction.Left
                }
            }
        }

        const direction = newDirection()

        if (mover.move(squid, direction) === true) {
            squid.direction = direction
        } else {
            mover.move(squid, squid.direction)
        }
    }

    private getMover(monster: Monster, interval: number): MobileObjectMovement {
        let mover = this.movers.get(monster)
        if (!mover) {
            mover = new MobileObjectMovement(this.map, this.bombs, interval)
            this.movers.set(monster, mover)
        }
        return mover
    }
}
