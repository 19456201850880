import {CellType, MutableMap} from "../models/map";
import {Bombs} from "../models/bombs";
import {ExplosionCalculator} from "./explosion-calculator";
import {Explodes} from "../models/explodes";

export class BricksExploder {
    constructor(
        private readonly map: MutableMap,
        private readonly bombs: Readonly<Bombs>,
        private readonly explodes: Explodes,)
    {}

    public explodeBricks() {
        for (const explosion of this.bombs.explosions) {
            for (const cell of ExplosionCalculator.eachCellIn(explosion)) {
                if (this.map.get(cell) === CellType.Bricks) {
                    this.map.set(cell, CellType.Empty)
                    this.explodes.bricks.push({
                        cell,
                        removeAt: performance.now() + 3000
                    })
                }
            }
        }
    }
}
