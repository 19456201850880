import {Bomb, Bombs, Explosion} from "../models/bombs";
import {Map} from "../models/map";
import {ExplosionCalculator} from "./explosion-calculator";

interface Params {
    readonly explodeSize: number
}

export class BombsHandler {
    private explosionCalculator: ExplosionCalculator

    constructor(
        private readonly map: Readonly<Map>,
        private readonly bombs: Bombs,
        private readonly params: Params
    ) {
        this.explosionCalculator = new ExplosionCalculator(map)
    }

    public explodeBombs(): void {
        const now = performance.now()
        const bombs = new Set(this.bombs.active)
        const newExplosions = new Set<Explosion>()

        const explode = (bomb: Bomb) => {
            const explosion = this.explosionCalculator.explode(bomb, this.params.explodeSize)
            explosion.removeAt = now + 3000
            newExplosions.add(explosion)
            bombs.delete(bomb)
        }

        for(const bomb of this.bombs.active) {
            if (bombs.has(bomb)) {
                if (bomb.explodeAt !== undefined && bomb.explodeAt <= now) {
                    explode(bomb)
                }
                if(this.bombs.explosions.find(e => ExplosionCalculator.isInExplosion(bomb.position, e))) {
                    explode(bomb)
                }
            }
        }

        if (bombs.size !== this.bombs.active.length) {
            this.bombs.active = Array.from(bombs.values())
        }
        this.bombs.explosions.push(...newExplosions.values())
    }
}
