import {Explodes} from "../models/explodes";

export class ExplodesRemover {
    constructor(
        private readonly explodes: Explodes,
    ) {
    }

    public removeExplodes(): void {
        const now = performance.now()
        this.explodes.bricks = this.explodes.bricks.filter(b => b.removeAt > now)
        this.explodes.monsters = this.explodes.monsters.filter(b => b.removeAt > now)
    }
}
