import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Scene from './Scene';
import reportWebVitals from './reportWebVitals';
import {SceneGenerator} from "./engine/generators/scene-generator";
import {SceneRender} from "./render/scene-render";
import {GameLoop} from "./engine/game-loop";
import {KeyCommandsEmitter} from "./engine/input/key-command-emitter";
import {JoinEmitter} from "./engine/input/join-emitter";
import {FunctionCommandsEmitter} from "./engine/input/function-command-emitter";
import Controls from "./Controls";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const scene = new SceneGenerator({ map: { size: { width: 31, height: 15 } } }).generate()

const renderer = new SceneRender(scene)

const functionEmitter = new FunctionCommandsEmitter()

const emitter = new JoinEmitter([new KeyCommandsEmitter(), functionEmitter])

const gameLoop = new GameLoop(scene, emitter)

function refresh() {
    gameLoop.next()

    requestAnimationFrame(() => {
        const buffer = renderer.render()

        root.render(
            <React.StrictMode>
                <Scene buffer={buffer} />
                <Controls
                    onCommandDeactivated={functionEmitter.onCommandDeactivated}
                    onCommandActivated={functionEmitter.onCommandActivated}
                    active={functionEmitter.active} />
            </React.StrictMode>
        );

        refresh()
    })
}
refresh()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
