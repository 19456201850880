import {Point} from "./point";

export interface MobileObject {
    position: Position

    direction: Direction
}

export interface Position {
    cell: Point

    shift: Shift
}

export interface Shift {
    width: number

    height: number
}

export enum Direction {
    Up = 'up',
    Down = 'down',
    Left = 'left',
    Right = 'right',
}
