import {Command} from "./command";
import {CommandAction, CommandsEmitter} from "./command-emitter";
import {SimpleEmitter} from "./emitter";

export interface CommandGenerator {
    onCommandActivated: (command: Command) => void

    onCommandDeactivated: (command: Command) => void

    get active(): Array<Command>
}

export class FunctionCommandsEmitter implements CommandsEmitter, CommandGenerator {
    private readonly activateEmitter = new SimpleEmitter<Command>()

    private readonly deactivateEmitter = new SimpleEmitter<Command>()

    private readonly activeCommands = new Set<Command>()

    addListener(activate: CommandAction, deactivate?: CommandAction): void {
        this.activateEmitter.addListener(activate)
        if (deactivate) {
            this.deactivateEmitter.addListener(deactivate)
        }
    }

    removeListener(activate: CommandAction, deactivate?: CommandAction): void {
        this.activateEmitter.removeListener(activate)
        if (deactivate) {
            this.deactivateEmitter.removeListener(deactivate)
        }
    }

    get active(): Array<Command> {
        return Array.from(this.activeCommands.values())
    }

    onCommandActivated = (command: Command): void => {
        this.activateEmitter.emit(command)
        this.activeCommands.add(command)
    }

    onCommandDeactivated = (command: Command): void => {
        this.deactivateEmitter.emit(command)
        this.activeCommands.delete(command)
    }
}
