import {Bombs} from "../models/bombs";
import {ExplosionCalculator} from "./explosion-calculator";
import {Player} from "../models/player";

export class PlayerExploder {
    constructor(
        private readonly player: Player,
        private readonly bombs: Readonly<Bombs>,)
    {}

    public explodePlayer() {
        this.player.isDead = this.player.isDead ||
            this.bombs.explosions.some(explosion => ExplosionCalculator.isInExplosion(this.player.position.cell, explosion))
    }
}
