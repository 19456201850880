import {MobileObject} from "./mobile";

export interface Monsters {
    eels: Array<Monster>

    squids: Array<Monster>
}

export interface Monster extends MobileObject {

}

export function all(monsters: Monsters): Array<Monster> {
    return monsters.eels.concat(monsters.squids)
}
