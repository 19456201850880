import {Bombs, Bomb, Explosion} from "../models/bombs";

export class BombsGenerator {
    public generate(): Bombs {
        return {
            active: new Array<Bomb>(),
            explosions: new Array<Explosion>(),
        }
    }
}
