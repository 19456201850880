import {Sprite, SpriteFlip} from "./render-buffer";
import {Direction, MobileObject} from "../engine/models/mobile";

export interface Params {
    sideImageUrl: string

    topImageUrl: string
}

export function getMobileSymbol(mobile: Readonly<MobileObject>, symbol: string): Partial<Sprite> {
    return {
        symbol,
        shift: mobile.position.shift,
    }
}

export function getMobileSprite(mobile: Readonly<MobileObject>, params: Params): Partial<Sprite> {
    return {
        ...mapDirection(mobile.direction, params),
        shift: mobile.position.shift,
    }
}

function mapDirection(direction: Direction, params: Params): { imageUrl: string, flip: SpriteFlip } {
    switch (direction) {
        case Direction.Up: return { imageUrl: params.topImageUrl, flip: { height: true }}
        case Direction.Left: return { imageUrl: params.sideImageUrl, flip: { width: false }}
        case Direction.Right: return { imageUrl: params.sideImageUrl, flip: { width: true }}
        case Direction.Down: return { imageUrl: params.topImageUrl, flip: { height: false }}
    }
}