import {RenderBuffer} from "./render-buffer";
import {Player} from "../engine/models/player";
import {getMobileSprite} from "./mobile-render";

export function renderPlayer(player: Readonly<Player>, buffer: RenderBuffer): void {
    const cell = buffer[player.position.cell.height][player.position.cell.width]

    const sprites = player.isDead
        ? { sideImageUrl: 'dragon_died_2.png', topImageUrl:'dragon_died_2.png' }
        : { sideImageUrl: 'dragon_side_2.png', topImageUrl:'dragon_top_2.png' }

    cell.sprites.push({
        ...getMobileSprite(player, sprites)
    })
}
