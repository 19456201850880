import {Player} from "../models/player";
import {Direction} from "../models/mobile";

export class PlayerGenerator {
    public generate(): Player {
        return {
            position: { cell: { width: 1, height: 1 }, shift: { width: 0, height: 0 }},
            direction: Direction.Right,
            isDead: false,
        }
    }
}
