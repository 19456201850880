import {CellType, Map} from "../models/map";
import {Point} from "../models/point";
import {Bomb, Explosion} from "../models/bombs";

export class ExplosionCalculator {
    constructor(
        private readonly map: Readonly<Map>,) {
    }

    public explode(bomb: Bomb, size: number): Explosion {
        const position = bomb.position
        return {
            position,
            left: this.getDistance(size, i => this.map.get({ width: position.width - i, height: position.height })),
            right: this.getDistance(size, i => this.map.get({ width: position.width + i, height: position.height })),
            top: this.getDistance(size, i => this.map.get({ width: position.width, height: position.height - i })),
            bottom: this.getDistance(size, i => this.map.get({ width: position.width, height: position.height + i })),
        }
    }

    public static isInExplosion(position: Point, explosion: Explosion): boolean {
        return (
            position.height >= explosion.position.height - explosion.top
            &&
            position.height <= explosion.position.height + explosion.bottom
            &&
            position.width === explosion.position.width
        ) || (
            position.width >= explosion.position.width - explosion.left
            &&
            position.width <= explosion.position.width + explosion.right
            &&
            position.height === explosion.position.height
        )
    }

    public static *eachCellIn(explosion: Explosion): Iterable<Point> {
        for (let w = explosion.position.width - explosion.left; w <= explosion.position.width + explosion.right; w++) {
            yield { height: explosion.position.height, width: w }
        }
        for (let h = explosion.position.height - explosion.top; h <= explosion.position.height + explosion.bottom; h++) {
            yield { height: h, width: explosion.position.width }
        }
    }

    private getDistance(size: number, cell: (coord: number) => CellType): number {
        for (let i = 1; i <= size; i++) {
            if (cell(i) === CellType.Wall) {
                return i - 1
            }
            if (cell(i) !== CellType.Empty){
                return i
            }
        }
        return size
    }
}
