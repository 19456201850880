import {KeyCommandsEmitter} from "../input/key-command-emitter";
import {Map} from "../models/map";
import {Command} from "../input/command";
import {Player} from "../models/player";
import {MobileObjectMovement} from "./mobile-object-movement";
import {Direction} from "../models/mobile";
import {Bombs} from "../models/bombs";
import {StateCommandsProvider} from "../input/command-providers";
import {CommandsEmitter} from "../input/command-emitter";

export class PlayerMovement {
    private readonly commandsProvider: StateCommandsProvider

    private mover: MobileObjectMovement

    constructor(
        public readonly commands: CommandsEmitter,
        private readonly map: Map,
        private readonly bombs: Bombs,
        private readonly player: Player,
    ) {
        this.mover = new MobileObjectMovement(map, bombs)
        this.commandsProvider = new StateCommandsProvider(commands)
    }

    public move(): void {
        if (this.player.isDead) {
            return
        }

        if (this.commandsProvider.isActive(Command.Up)) {
            this.mover.move(this.player, Direction.Up)
        }
        if (this.commandsProvider.isActive(Command.Down)) {
            this.mover.move(this.player, Direction.Down)
        }
        if (this.commandsProvider.isActive(Command.Left)) {
            this.mover.move(this.player, Direction.Left)
        }
        if (this.commandsProvider.isActive(Command.Right)) {
            this.mover.move(this.player, Direction.Right)
        }
    }
}
