import {RenderBuffer} from "./render-buffer";
import {Explodes} from "../engine/models/explodes";

export function renderExplodes(explodes: Explodes, buffer: RenderBuffer): void {
    for (const explode of explodes.bricks) {
        const cell = buffer[explode.cell.height][explode.cell.width]
        cell.sprites.push({imageUrl: 'seaweed_fired.png'})
    }

    for (const explode of explodes.monsters) {
        const cell = buffer[explode.position.cell.height][explode.position.cell.width]
        cell.sprites.push({imageUrl: 'eel_died.png', shift: explode.position.shift })
    }
}
