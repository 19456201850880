import {Point} from "./point";
import {Size} from "./size";
import {arrayOf} from "../../common/array-2d";

export interface Map {
    readonly size: Size

    get(point: Point): CellType
}

export interface MutableMap extends Map {
    set(point: Point, cell: CellType): void
}

export enum CellType {
    Empty = 0,
    Wall = 1,
    Bricks = 2,
}

export class ArrayMap implements MutableMap {
    private readonly data: Array<Array<CellType>>

    constructor(public readonly size: Size) {
        this.data = arrayOf(size.height, size.width, CellType.Empty)
    }

    get(point: Point): CellType {
        return this.data[point.height][point.width];
    }

    set(point: Point, cell: CellType): void {
        this.data[point.height][point.width] = cell
    }
}

export function forEach(map: Map, callback: (point: Point, value: CellType) => void): void {
    for (let height = 0; height < map.size.height; height++) {
        for (let width = 0; width < map.size.width; width++) {
            const point: Point = { width, height }
            callback(point, map.get(point))
        }
    }
}

export function *columns(map: Map, height: number): Iterable<CellType> {
    for (let width = 0; width < map.size.width; width++) {
        yield map.get({ width, height })
    }
}

export function *rows(map: Map): Iterable<Iterable<CellType>> {
    for (let height = 0; height < map.size.height; height++) {
        yield columns(map, height)
    }
}

export function isEdge(map: Map, point: Point): boolean {
    return point.height === 0 || point.width === 0 || point.height === map.size.height - 1 || point.width === map.size.width - 1
}
