import {Player} from "../models/player";
import {all, Monsters} from "../models/monsters";

export class PlayerMonsterCollisions {
    constructor(
        private readonly player: Player,
        private readonly monsters: Readonly<Monsters>,
    ) {
    }

    public check() {
        this.player.isDead = this.player.isDead ||
            all(this.monsters).some(k =>
                k.position.cell.width === this.player.position.cell.width &&
                k.position.cell.height === this.player.position.cell.height)
    }
}