import React, {useCallback} from 'react';
import './Controls.css';
import {Command} from "./engine/input/command";
import {CommandGenerator} from "./engine/input/function-command-emitter";

function Controls(params: CommandGenerator) {
    const upDown = useCallback(() => params.onCommandActivated(Command.Up), [params.onCommandActivated])
    const downDown = useCallback(() => params.onCommandActivated(Command.Down), [params.onCommandActivated])
    const leftDown = useCallback(() => params.onCommandActivated(Command.Left), [params.onCommandActivated])
    const rightDown = useCallback(() => params.onCommandActivated(Command.Right), [params.onCommandActivated])
    const putDown = useCallback(() => params.onCommandActivated(Command.Put), [params.onCommandActivated])

    const upUp = useCallback(() => params.onCommandDeactivated(Command.Up), [params.onCommandDeactivated])
    const downUp = useCallback(() => params.onCommandDeactivated(Command.Down), [params.onCommandDeactivated])
    const leftUp = useCallback(() => params.onCommandDeactivated(Command.Left), [params.onCommandDeactivated])
    const rightUp = useCallback(() => params.onCommandDeactivated(Command.Right), [params.onCommandDeactivated])
    const putUp = useCallback(() => params.onCommandDeactivated(Command.Put), [params.onCommandDeactivated])

    return (
        <div className="controls">
            <div className="movement">
                <div className="row row1">
                    <div className="button up" onMouseDown={upDown} onMouseUp={upUp} onTouchStart={upDown} onTouchEnd={upUp}>
                        <img src="images/control_up.png" title="" alt=""/>
                    </div>
                </div>
                <div className="row row2">
                    <div className="button left" onMouseDown={leftDown} onMouseUp={leftUp} onTouchStart={leftDown} onTouchEnd={leftUp}>
                        <img src="images/control_left.png" title="" alt=""/>
                    </div>
                    <div className="button right" onMouseDown={rightDown} onMouseUp={rightUp} onTouchStart={rightDown} onTouchEnd={rightUp}>
                        <img src="images/control_right.png" title="" alt=""/>
                    </div>
                </div>
                <div className="row row3">
                    <div className="button down" onMouseDown={downDown} onMouseUp={downUp} onTouchStart={downDown} onTouchEnd={downUp}>
                        <img src="images/control_down.png" title="" alt="" />
                    </div>
                </div>
            </div>
            <div className="actions">
                <div className="row row1">
                    <div className="button flame" onMouseDown={putDown} onMouseUp={putUp} onTouchStart={putDown} onTouchEnd={putUp}>
                        <img src="images/control_flame.png" title="" alt=""/>
                    </div>
                </div>
             </div>
        </div>
    );
}

export default Controls;
