export interface Emitter<T> {
    addListener: (listener: Listener<T>) => void

    removeListener: (listener: Listener<T>) => void
}

export type Listener<T> = (param: T) => void

export class SimpleEmitter<T> implements Emitter<T> {
    private readonly listeners = new Array<Listener<T>>()

    public addListener(listener: Listener<T>): void {
        if (listener) {
            this.listeners.push(listener)
        }
    }

    public removeListener(listener: Listener<T>): void {
        const index = this.listeners.indexOf(listener)
        if (index >= 0) {
            this.listeners.splice(index, 1)
        }
    }

    public emit(param: T) {
        for (const listener of this.listeners){
            listener(param)
        }
    }
}
