import {Size} from "../models/size";
import {ArrayMap, CellType, forEach, isEdge, MutableMap} from "../models/map";

export class MapGenerator {
    constructor(public params: MapParameters) {
    }

    public generate(): MutableMap {
        const map = new ArrayMap(this.params.size)

        this.setEmpty(map)
        this.addWalls(map)
        this.addBricks(map)

        return map
    }

    private setEmpty(map: MutableMap) {
        forEach(map, point => {
            map.set(point, CellType.Empty)
        })
    }

    private addWalls(map: MutableMap) {
        forEach(map, point => {
            if (isEdge(map, point)) {
                map.set(point, CellType.Wall)
            }

            if (point.height % 2 === 0 && point.width % 2 === 0) {
                map.set(point, CellType.Wall)
            }
        })
    }

    private addBricks(map: MutableMap) {
        forEach(map, point => {
            if (map.get(point) === CellType.Empty && (Math.ceil(Math.random() * 100) ) % 10 === 0) {
                map.set(point, CellType.Bricks)
            }
        })
    }
}

export interface MapParameters{
    readonly size: Size
}
