import {Command} from "./command";
import {CommandsEmitter} from "./command-emitter";

export class StateCommandsProvider {
    private readonly activeCommands = new Set<Command>()
    private readonly atLeastOnceCommands = new Set<Command>()

    private readonly allowedCommands = new Set<Command>([Command.Down, Command.Left, Command.Right, Command.Up])

    constructor(emitter: CommandsEmitter) {
        emitter.addListener(this.onCommandActivate, this.onCommandDeactivate)
    }

    public isActive(command: Command): boolean {
        return this.activeCommands.has(command) || this.atLeastOnceCommands.delete(command)
    }

    private onCommandActivate = (command: Command) => {
        if (this.allowedCommands.has(command)) {
            this.activeCommands.add(command)
            this.atLeastOnceCommands.add(command)
        }
    }

    private onCommandDeactivate = (command: Command) => {
        this.activeCommands.delete(command)
    }
}

export class EventCommandsProvider {
    private readonly commands = new Array<Command>()

    private readonly allowedCommands = new Set<Command>([Command.Put])

    constructor(emitter: CommandsEmitter) {
        emitter.addListener(this.onNewCommand)
    }

    public get(): Command | undefined {
        return this.commands.pop()
    }

    private onNewCommand = (command: Command) => {
        if (this.allowedCommands.has(command)) {
            this.commands.push(command)
        }
    }
}
