import {CommandAction, CommandsEmitter} from "./command-emitter";

export class JoinEmitter implements CommandsEmitter {
    constructor(private readonly emitters: Array<CommandsEmitter>) {
    }

    addListener(activate: CommandAction, deactivate?: CommandAction): void {
        for (const emitter of this.emitters) {
            emitter.addListener(activate, deactivate)
        }
    }

    removeListener(activate: CommandAction, deactivate?: CommandAction): void {
        for (const emitter of this.emitters) {
            emitter.removeListener(activate, deactivate)
        }
    }
}
